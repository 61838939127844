const Drawer = require("./drawer.js");
let particles = [];
let drawers = [];
const draw_stage = new PIXI.Container();


let sprite_img;

let isTimeToJoint = 0;
let clickStart;
let container;
let _w = window.innerWidth;
let _h = window.innerHeight;
let _x = 500;
let _y = 500;
let currentFrame = 330;
let countFrame = 0;
let prefixFrame = "";

let pointForce = new PIXI.Point();
let pointEndForce = new PIXI.Point();
let forceToApply = new PIXI.Point();
let distance = 12;
let lastDrawer = 0;
let wire;
const app = new PIXI.Application({
  background: '#000000',
  autoResize: true,
  resolution: devicePixelRatio 
});
/*window.WebFontConfig = {
  google: {
      families: ['Manjari'],
  },
  active() {
      initFont();
  },
};

(function() {
  const wf = document.createElement('script');
  wf.src = `${document.location.protocol === 'https:' ? 'https' : 'http'
  }://ajax.googleapis.com/ajax/libs/webfont/1/webfont.js`;
  wf.type = 'text/javascript';
  wf.async = 'true';
  const s = document.getElementsByTagName('script')[0];
  s.parentNode.insertBefore(wf, s);
}());*/

let texturePromise = PIXI.Assets.load('./assets/dancer'+Math.round(Math.random()*3)+'.png');

texturePromise.then((resolvedTexture) => { 
  init(resolvedTexture);
});

async function init(img){
  sprite_img = PIXI.Sprite.from(img);
  sprite_img.position.set(0);

  matrixImg = await app.renderer.extract.pixels(sprite_img);
  draw_stage.width = window.innerWidth; // sprite_img.width;
  draw_stage.height = window.innerHeight; // sprite_img.height;
  draw_stage.position.x = (window.innerWidth / 2) - (sprite_img.width / 2);
  draw_stage.position.y = 0;
  app.stage.addChild(draw_stage);
  //document.querySelector('#draw_stage').appendChild(app.view);
  document.body.appendChild(app.view);

  // Listen for frame updates
  //app.ticker.deltaMS = 1;
  
  app.ticker.add(draw);
  app.ticker.add(draw);
  app.ticker.add(draw);
  app.ticker.add(draw);
  app.ticker.add(draw);

  
  
  window.addEventListener('resize', resize);
  window.addEventListener('click', letLine);
  //window.addEventListener('touchstart', letLineTouch);
  //setTimeout(nextFrame, 26000);

  putLine();

}
resize();

function draw(){
    if(!isTimeToJoint){
      for(let i=0; i<drawers.length; i++){
        drawers[i].update();
        drawers[i].show()
        drawers[i].edges();
        _x = 500;
        _y = 500;
      }
    }else if(isTimeToBlow){
      for(let i=0; i<drawers.length; i++){
        drawers[i].updateBlow();
      }
      if(countFrame % 25 == 0){
        currentFrame++;
        //letFrame();
      }
      countFrame++;
    }
    
}


function timeToJoint(){
  isTimeToJoint = 1;
  window.removeEventListener('click', letLine);
}

function nextFrame(){
  clearTimeout();
  //letFrame();
  /*currentFrame++;
  if(currentFrame > 99){
    prefixFrame = "";
  }else if(currentFrame > 9){
    prefixFrame = "0";
  }
  destroy();
  texturePromise = PIXI.Assets.load('./assets/silueta1_1/re_' + prefixFrame + currentFrame + '-margin.jpg');
  texturePromise.then((resolvedTexture) => { 
    init(resolvedTexture);
  });*/
  isTimeToBlow = true;
  isTimeToJoint = true;
}

function destroy(){
    drawers = [];
    particles = [];
    while(draw_stage.children[0]) { 
      draw_stage.removeChild(draw_stage.children[0]);
    }
    while(app.stage.children[0]) { 
        app.stage.removeChild(app.stage.children[0]);
    }
    document.body.removeChild(app.view);
    app.ticker.remove(draw);
    
}

function clickPoint(e){
  if(isTimeToJoint)
    pointForce = new PIXI.Point(e.x - draw_stage.x, e.y - draw_stage.y);
}

function releasePoint(e){
  if(isTimeToJoint){
    pointEndForce = new PIXI.Point(e.x - draw_stage.x, e.y - draw_stage.y);
    forceToApply = new PIXI.Point(pointEndForce.x - pointForce.x, pointEndForce.y - pointForce.y);
  }
}

function distanceBetween(p1, p2){
  return Math.hypot(p2.x - p1.x, p2.y - p1.y);
}

function initFont() {
  const textStyle = new PIXI.TextStyle({
    fontFamily: 'Manjari',
    fontSize: '21px',
    fill: 'white',
    align: 'right'
  });

  const textIntro = new PIXI.Text('dibujo líneas', textStyle);
  textIntro.cursor = 'pointer';
  textIntro.anchor.set(0);
  textIntro.position.set(20, 20);
  app.stage.addChild(textIntro);

  const overlayElement = document.querySelector("#overlay");
  const overlayClickScreen = PIXI.Sprite.from(overlayElement);
  const clickScreen = new PIXI.Text('click para empezar...', textStyle);
  const clickScreenMask = new PIXI.Text('click para empezar...', textStyle);
  clickStart = new PIXI.Container();
  [clickScreen, clickScreenMask, overlayClickScreen].forEach((sprite) => {
    sprite.anchor.set(0.5);
    sprite.position.x = _w / 2;
    sprite.position.y = _h / 2;
    clickStart.addChild(sprite);
  });
  overlayClickScreen.scale = new PIXI.Point(0, 0);
  overlayClickScreen.position.x = _w / 2 - clickScreen.width;
  overlayClickScreen.position.y = _h / 2 ;
  const timeline2 = gsap.timeline({repeat: -1, repeatDelay: 1});
  timeline2.add(gsap.to(overlayClickScreen.scale, { x: 8, y: 8 }));
  timeline2.add(gsap.to(overlayClickScreen.scale, { x: 0, y: 0 }));
  overlayClickScreen.scale = new PIXI.Point(0, 0);
  overlayClickScreen.mask = clickScreenMask;
  clickScreen.interactive =  true;
  app.stage.addChild(clickStart);

  const textSave = new PIXI.Text('-> descargar dibujo', textStyle);
  const textMask = new PIXI.Text('-> descargar dibujo', textStyle);
  container = new PIXI.Container();
  const overlaySprite = PIXI.Sprite.from(overlayElement);

  [textSave, textMask, overlaySprite].forEach((sprite) => {
    sprite.anchor.set(0);
    sprite.position.x = 20;
    sprite.position.y = 40;
    container.addChild(sprite);
  });

  overlaySprite.scale = new PIXI.Point(0, 0);
  overlaySprite.anchor.set(0.5);
  overlaySprite.mask = textMask;
  textSave.interactive =  true;
  textSave.cursor = "pointer";
  
  const timeline = gsap.timeline({repeat: -1, repeatDelay: 1.5});
  timeline.add(gsap.to(overlaySprite.scale, { x: 8, y: 8 }));
  timeline.add(gsap.to(overlaySprite.scale, { x: 0, y: 0 }));
  textSave.on('pointerdown', letFrame);
  
  resize();
}


function letFrame(){
  downloadSpriteAsImage('girldance'+currentFrame+'.jpg');
}

function letLineTouch(e){
  let x = e.touches[0].clientX * ratio;
  let y = e.touches[0].clientY * ratio;
  if(y > 80){
    let d = new Drawer(new PIXI.Point(x, y), sprite_img, matrixImg);
      d.position.set(0, 0);
      draw_stage.addChild(d);
      drawers.push(d);
    app.stage.removeChild(clickStart);
    app.stage.addChild(container);
  }
}

function putLine(){
  setFrameImage();
  let d = new Drawer(new PIXI.Point(_x, _y), sprite_img, matrixImg);
  d.position.set(0, 0);
  draw_stage.addChild(d);
  drawers.push(d);
}

function letLine(e){
  if(e.y > 80){
    let d = new Drawer(new PIXI.Point(e.x - draw_stage.x, e.y), sprite_img, matrixImg);
    d.position.set(0, 0);
    draw_stage.addChild(d);
    drawers.push(d);
    //app.stage.removeChild(clickStart);
    //app.stage.addChild(container);
  }
}

function resize() {
  app.renderer.resize(window.innerWidth, window.innerHeight);
  draw_stage.position.set(0, 0);
	// Resize the renderer
}

function setFrameImage(){
  const gr = new PIXI.Graphics();  
  gr.position.set(0);
  gr.lineStyle(10, 0xffffff);
  gr.moveTo(0, 0);
  gr.lineTo(1 , 1);
  gr.endFill();
  
  draw_stage.addChild(gr);

  const gr2 = new PIXI.Graphics();  
  gr2.position.set(0);
  gr2.lineStyle(10, 0xffffff);
  gr2.moveTo(_w, _h);
  gr2.lineTo(_w - 1, _h - 1);
  gr2.endFill();
  draw_stage.addChild(gr2);
}

async function downloadSpriteAsImage(fileName) {
  const img = await app.renderer.extract.base64(draw_stage, "image/jpeg", 1);
  //const blob = PIXI.BaseTexture.from(dataURL).data;
  const a = document.createElement('a');
  a.download = 'girldance/' + fileName;
  a.href = img; //URL.createObjectURL(img);

  document.body.append(a);

  a.click();
  a.remove();

  /*renderer.extract.canvas(sprite).toBlob(function (b) {
    var a = document.createElement('a');
    document.body.append(a);
    a.download = fileName;
    a.href = URL.createObjectURL(b);
    a.click();
    a.remove();
  }, 'image/jpeg');*/
}

