module.exports = class Particle extends PIXI.Graphics{
    constructor(pos, ppos){
        super();
        this.position.set(pos.x, pos.y);
        this.vel = new PIXI.Point(Math.random(), Math.random());
        this.acc = new PIXI.Point();
        this.mass = 3 * Math.hypot(pos.x, pos.y);

        //this.gr = new PIXI.Graphics();
        this.lineStyle(1, 0xffffff, 0.3);
        this.moveTo(ppos.x, ppos.y);
        this.lineTo(this.position.x , this.position.y);
        this.endFill();
        this.position.set(0);
        //this.addChild(this.gr);
    }
  
    update() {
      this.vel = this.add(this.acc, this.vel);
      //this.vel = this.limit(this.vel, 26);
      let pos = new PIXI.Point(this.position.x, this.position.y)
      pos = this.add(this.vel, pos);
      this.position.set(pos.x, pos.y);
      this.acc = this.multiplyScalar(0, this.acc);
    }

    applyForce(force) {
      const f = this.div(force, this.mass);
      this.acc = this.add(f, this.acc);
    }

    applyPointForce() {
      const force = new PIXI.Point(5 * Math.random(), 5 * Math.random());
      const f = this.div(force, this.mass);
      this.acc = this.add(f, this.acc);
    }

    add(v1, v2){
      return new PIXI.Point(v2.x + v1.x, v2.y + v1.y);
    }

    multiplyScalar(value, vector){
      return new PIXI.Point(value * vector.x, value * vector.y);
    }

    div(vector, value){
      return new PIXI.Point(vector.x / value, vector.y / value);
    }

    show() {  
      //this.x = this.pos.x;
      //this.y = this.pos.y;
    }
  
    applyFriction(){
      if(this.vel.magnitude() > 0){
        this.vel.x *= -1;
      }
    }
  
    edges(){
      if (this.position.y > window.innerHeight - 10) {
        this.vel.y = -this.vel.y;
        this.position.y = window.innerHeight - 11
      }
  
      
    }
      
    limit(vector, max) {
      if (vector.magnitudeSquared() > max*max) {
        vector = vector.normalize();
        vector = vector.multiplyScalar(max, vector);
      }
      return vector;
    }
      
  }