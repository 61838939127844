const Particle = require("./particle.js");
module.exports = class Drawer extends PIXI.Container{
    constructor(p, s, m){
        super();
        this.G = new PIXI.Point(0, -0.56);
        this.blowx = new PIXI.Point(0.36, 0);
        this.blownx = new PIXI.Point(-0.36,0); 
        this.particles = [];
        this.pos = p;
        this.ppos = p;
        this.sprite = s;
        this.matrixImg = m;
        this.vel = new PIXI.Point(Math.random(), Math.random());
        this.acc = new PIXI.Point();
        this.force = new PIXI.Point();
        this.m = 50;
        
        this.noiseScale = 2.50;
        this.noiseInfluence = 1 / 2.0;
        this.bound = 50.0;
        this.boundForceFactor = 0.36;
        this.maxSpeed = 5;
        this.perception = 50;
        this.seed = Math.random();
        
        this.vel = this.multiplyScalar(5, this.vel);
       
        this.vel = new PIXI.Point();
        this.acc = new PIXI.Point();
        this.mass = 3 * Math.sqrt(Math.pow(this.pos.x,2) + Math.pow(this.pos.y,2));
        //this.position.set(0,0);
    }

    update(){
      this.ppos = this.pos;
      this.force = this.multiplyScalar(0, this.force);
      
      let target = new PIXI.Point();
      let count = 0;
      let p = new PIXI.Point();
      for (let i = -Math.floor(this.perception/2) ; i < this.perception/2 ; i++ ) {
        for (let j = -Math.floor(this.perception/2) ; j < this.perception/2 ; j++ ) {
          if (i == 0 && j == 0)
            continue;
          let x = Math.floor(this.pos.x + i);
          let y = Math.floor(this.pos.y + j);
          if (x <= this.sprite.width - 1 && x >= 0 && y < this.sprite.height-1 && y >= 0) {
            let c = this.fget(x, y);
            let b = this.rgbToHsb(c)[2];
            //b = this.mapRange(b, 0, 100, 255, 0);
            b = 1 - b / 50.0;
            p = new PIXI.Point(i, j);
            
            p = this.normalize(p);
            p = this.multiplyScalar(b, p);
            p = this.div(this.magnitude(p), p);
            target = this.add(p, target);
            
            //this.force = this.add(p, this.force);
            count++;
          }
        }
      }
      
      if (count != 0) {
        this.force = this.add(this.div(count, target), this.force);
      }
      
       // Add noise force
      let n = noise.simplex2(this.pos.x/this.noiseScale, this.pos.y/this.noiseScale, this.seed);
      n = this.mapRange(n, 0, 1, 0, 5* Math.PI * 2);
      p = this.vectorFromAngle(n);
      
      if(this.magnitude(this.force) < 0.01){
        p = this.multiplyScalar(this.noiseInfluence * 5, p);
      }else{
        p = this.multiplyScalar(this.noiseInfluence, p);
      }
      this.force = this.add(p, this.force);

      
      // Add bound force
      let boundForce = new PIXI.Point();
      if (this.pos.x < this.bound) {
        boundForce.x = (this.bound-this.pos.x)/this.bound;
      } 
      if (this.pos.x > this.sprite.width - this.bound) {
        boundForce.x = (this.pos.x - this.sprite.width)/this.bound;
      } 
      if (this.pos.y < this.bound) {
        boundForce.y = (this.bound-this.pos.y)/this.bound;
      } 
      if (this.pos.y > this.sprite.height - this.bound) {
        boundForce.y = (this.pos.y - this.sprite.height)/this.bound;
      } 
      this.force = this.add(this.multiplyScalar(this.boundForceFactor, boundForce), this.force);
      
      this.vel = this.add(this.force, this.vel);
      this.vel = this.multiplyScalar(0.9999, this.vel);
      if (this.magnitude(this.vel) > this.maxSpeed) {
        this.vel = this.multiplyScalar(this.maxSpeed/this.magnitude(this.vel), this.vel);
      }
      
      this.pos = this.add(this.vel, this.pos);
      //this.seed += 0.001;
    }
    
    applyForce(f, m) {
      this.div(m, f);
      this.acc = this.add(this.f, this.acc);
    }
    
    edges(){
      if(this.pos.x < 0){
        this.pos.x = this.sprite.width-1;
      }else if(this.pos.x > this.sprite.width){
        this.pos.x = 0;
      }
      if(this.pos.y < 0){
        this.pos.y= this.sprite.height-1;
      }else if(this.pos.y > this.sprite.height){
        this.pos.y = 1;
      }
    }
  
    show (){
      const p = new Particle(this.pos, this.ppos);
      this.particles.push(p);
      this.addChild(p);
    }

    updateBlow() {
      for(let i=0; i<this.particles.length; i++){
        this.particles[i].update();
        this.particles[i].show();
        this.particles[i].applyForce(this.G);
        if(Math.random()* 6 % 2 == 0){
          this.particles[i].applyForce(this.blowx);
        }else{
          this.particles[i].applyForce(this.blownx);
        }
        //this.particles[i].edges();
      }
    }

    applyForce(force) {
      const f = this.div(force, this.mass);
      this.acc = this.add(f, this.acc);
    }

    showBlow() {  
      this.position.set(this.pos.x, this.pos.y);
     }

    
    add(v1, v2){
      return new PIXI.Point(v2.x + v1.x, v2.y + v1.y);
    }

    multiplyScalar(value, vector){
      return new PIXI.Point(value * vector.x, value * vector.y);
    }

    div(value, vector){
      return new PIXI.Point(vector.x / value, vector.y / value);
    }

    mapRange (value, a, b, c, d) {
        // first map value from (a..b) to (0..1)
        value = (value - a) / (b - a);
        // then map it from (0..1) to (c..d) and return it
        return c + value * (d - c);
    }

    vectorFromAngle(angle, length){
        if (typeof length === 'undefined') {
          length = 1;
        }
        return new PIXI.Point(length * Math.cos(angle), length * Math.sin(angle));
    }

    magnitude(p){
        return Math.sqrt((p.x * p.x) + (p.y * p.y)); 
    }

    static magnitude(p){
      return Math.sqrt((p.x * p.x) + (p.y * p.y)); 
    }

    normalize(p) {
        const len = this.magnitude(p);
        // here we multiply by the reciprocal instead of calling 'div()'
        // since div duplicates this zero check.
        if (len !== 0) this.multiplyScalar(1 / len, p);
        return p;
    }

    fget(i, j) {
      var index = j * this.sprite.width + i;
      index *= 4;
     
      return [this.matrixImg[index], 
              this.matrixImg[index+1], 
              this.matrixImg[index+2], 
              this.matrixImg[index+3]];
    }

    rgbToHsl(arr) {
      var r = arr[0] / 255,
        g = arr[1] / 255,
        b = arr[2] / 255;
      var max = Math.max(r, g, b),
        min = Math.min(r, g, b);
      var h, s, l = (max + min) / 2;
    
      if (max == min) {
        h = s = 0;
      } else {
        var d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch (max) {
          case r:
            h = (g - b) / d + (g < b ? 6 : 0);
            break;
          case g:
            h = (b - r) / d + 2;
            break;
          case b:
            h = (r - g) / d + 4;
            break;
        }
        h /= 6;
      }
      return [
        Math.round(h * 360),
        Math.round(s * 100),
        Math.round(l * 100)
      ];
    }
    
  rgbToHsb (arr) {
    let r = arr[0]; 
    let g = arr[1]; 
    let b = arr[2];
    r /= 255;
    g /= 255;
    b /= 255;
    const v = Math.max(r, g, b),
      n = v - Math.min(r, g, b);
    const h =
      n === 0 ? 0 : n && v === r ? (g - b) / n : v === g ? 2 + (b - r) / n : 4 + (r - g) / n;
    return [60 * (h < 0 ? h + 6 : h), v && (n / v) * 100, v * 100];
  }

  
}